import '../../css/index.css'

import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { groupBy, isEmpty, map } from 'lodash'

import { useReportsBacklogApi } from '@ephemeris/fulfillment-api/src/useReportsBacklogApi'
import ReadingIpad from '../../images/reading.png'
import ForecastingIpad from '../../images/forecasting.png'
import SaturnCyclesIpad from '../../images/saturnCycles.png'
import SoulAtlasBanner from '../../images/soul-atlas-banner.jpg'

import type { PageProps } from 'gatsby'
import type { FC } from 'react'

const REPORT_TYPES_ORDER: ReportType[] = [
  'reading',
  'forecasting',
  'saturnCycles',
]

function getIpadImageSource(reportType: ReportType): string {
  switch (reportType) {
    case 'reading':
      return ReadingIpad
    case 'forecasting':
      return ForecastingIpad
    case 'saturnCycles':
      return SaturnCyclesIpad
    default:
      return ReadingIpad
  }
}

const Loader: FC<{ style: 'light' | 'dark' }> = ({ style = 'light' }) => {
  const color = style === 'light' ? 'white' : 'black'
  return (
    <svg
      className='animate-spin w-5 h-5 mr-3 -ml-1 text-white'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke={color}
        stroke-width='4'
      ></circle>
      <path
        className='opacity-75'
        fill={color}
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  )
}

const EphemerisLogo: React.FC = () => (
  <svg
    width='100%'
    height='auto'
    viewBox='0 0 154 45'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M135.201 13.3758C135.201 13.3758 134.777 14.3891 134.777 18.6788V29.9604C134.777 34.2502 135.201 35.2297 135.201 35.2297H130.787C130.787 35.2297 131.211 34.2164 131.211 29.9604L131.261 18.6451C131.261 14.3553 130.787 13.3758 130.787 13.3758H135.201Z'
      fill='currentColor'
    />
    <path
      d='M70.4931 27.2583L69.7949 35.2297H55.1826C55.1826 35.2297 55.5567 34.1151 55.5567 29.8591L55.6065 18.6451C55.6065 14.3891 55.1826 13.4096 55.1826 13.4096H69.7949L70.4931 21.2797L69.8946 21.4148C67.7502 14.6256 65.0571 14.7944 59.2471 14.862C59.1723 15.6727 59.0975 16.8886 59.0975 18.7126V22.9686H65.1818V24.3872H59.0975V29.8929C59.0975 31.6831 59.1723 32.8991 59.2471 33.7097C65.6306 33.7773 67.6504 34.4528 69.8946 27.1569L70.4931 27.2583Z'
      fill='currentColor'
    />
    <path
      d='M15.6098 27.2583L14.9116 35.2297H0.274414C0.274414 35.2297 0.648448 34.1151 0.648448 29.8591L0.698319 18.6451C0.698319 14.3891 0.274414 13.4096 0.274414 13.4096H14.8867L15.6098 21.2797L15.0113 21.4148C12.8669 14.6256 10.1738 14.7944 4.36385 14.862C4.28904 15.6727 4.21424 16.8886 4.21424 18.7126V22.9686H10.2985V24.3872H4.21424V29.8929C4.21424 31.6831 4.28904 32.8991 4.36385 33.7097C10.7474 33.7773 12.7671 34.4528 15.0113 27.1569L15.6098 27.2583Z'
      fill='currentColor'
    />
    <path
      d='M110.913 27.2245L110.215 35.1959H95.603C95.603 35.1959 95.9771 34.0813 95.9771 29.8254L96.0269 18.5775C96.0269 14.3216 95.603 13.342 95.603 13.342H110.215L110.913 21.2459L110.315 21.381C108.171 14.5918 105.478 14.7269 99.6675 14.7945C99.5927 15.6051 99.5179 16.8211 99.5179 18.6451V22.901H105.602V24.3197H99.5179V29.8591C99.5179 31.6493 99.5927 32.8653 99.6675 33.676C106.051 33.7435 108.071 34.4191 110.315 27.1232L110.913 27.2245Z'
      fill='currentColor'
    />
    <path
      d='M33.6631 20.0637C33.6631 26.1099 29.7232 28.204 27.1299 28.204C25.3097 28.204 23.7387 27.6298 22.6166 26.9205V29.9267C22.6166 34.2164 23.0405 35.1959 23.0405 35.1959H18.6519C18.6519 35.1959 19.0758 34.1826 19.0758 29.9267L19.1256 18.6113C19.1256 14.3216 18.6519 13.342 18.6519 13.342H23.0655H27.4042C31.0947 13.3758 33.6631 15.47 33.6631 20.0637ZM30.0973 21.8201C30.0973 17.5642 27.9528 14.7945 25.2348 14.7945C24.5616 14.7945 23.7138 14.8282 22.7662 15.3349C22.6914 16.1456 22.6665 17.1927 22.6665 18.6789V24.9952C23.614 26.1099 25.0354 26.7854 26.8307 26.8192C28.5014 26.7854 30.0973 25.8734 30.0973 21.8201Z'
      fill='currentColor'
    />
    <path
      d='M51.6918 29.9605C51.6918 34.2164 52.1157 35.2297 52.1157 35.2297H47.7021C47.7021 35.2297 48.126 34.2164 48.126 29.9605L48.1509 24.421H39.9721V29.9605C39.9721 34.2164 40.396 35.2297 40.396 35.2297H36.0322C36.0322 35.2297 36.4063 34.2164 36.4063 29.9605L36.4561 18.6788C36.4561 14.4229 36.0322 13.4096 36.0322 13.4096H40.396C40.396 13.4096 39.9721 14.4229 39.9721 18.7126V22.9686H48.1509L48.1759 18.6788C48.1759 14.4229 47.7021 13.4096 47.7021 13.4096H52.1157C52.1157 13.4096 51.6918 14.4229 51.6918 18.7126V29.9605Z'
      fill='currentColor'
    />
    <path
      d='M144.926 12.3287C147.868 12.3287 150.287 13.342 152.756 15.4024L151.858 21.5161C149.24 14.7607 145.724 13.8487 144.377 13.8487C142.008 14.0513 141.435 15.8078 141.435 17.0575C141.435 19.557 143.529 20.4353 146.048 21.4486C149.763 23.0699 153.379 23.9819 153.379 28.9134C153.379 33.676 149.389 36.243 146.023 36.243C143.031 36.243 139.964 34.8919 137.62 32.1898L138.642 25.7721C140.163 31.548 143.804 34.5204 146.896 34.5204C148.866 34.5204 150.087 33.1355 150.087 31.548C150.087 29.2849 148.616 28.5756 145.225 27.1907C140.886 25.2992 138.368 23.9819 138.368 19.3882C138.368 16.078 140.587 12.3287 144.926 12.3287Z'
      fill='currentColor'
    />
    <path
      d='M143.878 40.1274C142.108 41.9852 139.49 43.066 136.672 43.066C131.984 43.066 128.917 37.3915 126.473 32.7977C125.401 30.8387 124.728 28.9809 123.78 27.8663C126.174 27.2245 128.618 25.029 128.618 20.0299C128.618 15.47 126.049 13.3758 122.334 13.3758H117.995H113.582C113.582 13.3758 114.055 14.3553 114.055 18.6113L114.005 29.9942C114.005 34.2502 113.582 35.2297 113.582 35.2297H117.995C117.995 35.2297 117.571 34.2502 117.571 29.9942V26.8529C118.319 27.3258 119.267 27.7311 120.339 27.9338C120.913 28.0351 121.411 28.4742 121.686 29.1498C121.86 29.5551 122.06 30.028 122.234 30.4671C124.104 35.1284 127.969 44.8562 136.522 44.8562C139.589 44.8562 142.158 43.5727 144.427 40.9381C144.427 40.9381 145.624 39.6208 145.449 38.2021C145.499 38.2359 144.901 39.0465 143.878 40.1274ZM121.76 26.684C119.965 26.6503 118.519 26.0085 117.596 24.8939V18.6451C117.596 17.1926 117.646 16.1455 117.696 15.3349C118.643 14.8282 119.491 14.7944 120.165 14.7944C122.907 14.7944 125.027 17.5642 125.027 21.7863C125.052 25.8058 123.456 26.684 121.76 26.684Z'
      fill='currentColor'
    />
    <path
      d='M133.805 4.72882C133.256 3.98572 133.007 0 133.007 0C133.007 0 132.807 3.91817 132.209 4.72882C131.61 5.53948 128.718 5.8097 128.718 5.8097C128.718 5.8097 131.61 6.07992 132.209 6.89057C132.807 7.70123 133.007 11.6194 133.007 11.6194C133.007 11.6194 133.156 7.76878 133.805 6.89057C134.453 6.01236 137.296 5.8097 137.296 5.8097C137.296 5.8097 134.353 5.47192 133.805 4.72882Z'
      fill='currentColor'
    />
    <path
      d='M93.2092 35.2297H88.6211C88.646 34.2502 88.4715 32.9329 88.272 31.4804L86.6512 22.2592C86.3769 20.773 86.1026 19.4557 85.8283 18.3073L82.3623 35.1959H81.9134L81.315 33.1017V33.068L78.198 21.9215C77.7741 20.6041 77.45 19.4219 77.051 18.2397L75.1559 30.636C74.9564 32.1898 74.7569 33.9124 74.8567 35.1959L72.8618 35.2297C73.161 34.1826 73.56 32.1222 73.8094 30.6698L76.2281 15.9766C75.904 15.166 75.5549 14.2878 75.131 13.3758H79.4947C79.8687 15.166 80.2178 16.5509 80.6417 18.1384L82.9358 26.3463L85.3545 16.2469C85.0304 15.0309 84.7561 14.0513 84.5067 13.3758H88.8704C89.1447 15.8753 89.3941 17.4628 89.7183 19.3206L91.1645 26.9205C91.6882 29.6227 92.3614 32.9666 93.2092 35.2297Z'
      fill='currentColor'
    />
  </svg>
)

export const DownloadReports: FC<PageProps> = ({ params }) => {
  const { groupId } = params

  const [downloadsInfo, setDownloadsInfo] = useState<
    ReportsBacklog.DownloadInfo[]
  >([])
  useEffect(() => {
    const { getReportsDownloadInfo } = useReportsBacklogApi()
    getReportsDownloadInfo(groupId).then(setDownloadsInfo)
  }, [])

  const groups = groupBy(downloadsInfo, 'lineItemId')

  let groupIndex = 0

  return (
    <>
      <Helmet>
        <title>Ephemeris Reports</title>
      </Helmet>
      <div className='flex justify-center w-full p-4'>
        <a
          className='sm:max-w-xl w-full'
          href='https://ephemeris.co/discount/BOOK20?redirect=/products/soul-atlas'
          target='_blank'
          rel='noreferrer noopener'
        >
          <img src={SoulAtlasBanner} className='w-full' loading='lazy' />
        </a>
      </div>
      <div className='flex items-center justify-center p-4'>
        <div className='text-ephemerisBlue w-full max-w-[240px]'>
          <EphemerisLogo />
        </div>
      </div>
      {isEmpty(downloadsInfo) ? (
        <div className='absolute top-0 flex flex-col items-center justify-center w-screen h-screen'>
          <div className='flex items-center justify-center'>
            <Loader style='dark' />
            <span className='text-xl'>Loading your astrological readings</span>
          </div>
        </div>
      ) : (
        map(groups, group => {
          const [downloadInfo] = group
          const { nameOnReport } = downloadInfo
          const [firstName] = nameOnReport.split(' ')
          const downloadInfos = group.sort(
            (a, b) =>
              REPORT_TYPES_ORDER.indexOf(a.reportType) -
              REPORT_TYPES_ORDER.indexOf(b.reportType)
          )
          const backgroundColor =
            groupIndex++ % 2 === 0 ? 'bg-accent' : 'bg-lightGray'
          return (
            <div key={`${nameOnReport}-reports`}>
              <h2
                className={`
                  text-ephemerisBlue uppercase text-center mt-4 px-4 font-posterama font-bold text-sm md:text-md
                `}
              >
                {firstName}, enjoy your personal readings!
              </h2>
              <div
                className={`
          ${backgroundColor} px-12 mt-8 py-8
          md:flex md:justify-center md:mt-4
        `}
              >
                {map(downloadInfos, (downloadInfo, index) => {
                  const { reportUrl, reportType } = downloadInfo
                  const ipadImageSource = getIpadImageSource(reportType)

                  return (
                    <div
                      key={index}
                      className={`
                flex flex-col items-center mt-16 first:mt-0 z-10
                md:mt-0
              `}
                    >
                      <div
                        className={`
                flex justify-center items-center bg-white overflow-hidden rounded-full p-8 w-2/3-screen h-2/3-width-screen
                md:bg-transparent md:w-auto md:h-auto md:overflow-visible md:rounded-none md:pb-0
              `}
                      >
                        <div>
                          <img src={ipadImageSource} className='w-full' />
                        </div>
                      </div>
                      <a
                        className={`
                          bg-ephemerisBlue text-gray-50 rounded-full p-2 px-8 mt-8 font-posterama font-bold
                          md:mt-4 md:hover:shadow-md md:hover:text-white md:duration-200 md:transform md:hover:scale-110
                        `}
                        href={reportUrl}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        DOWNLOAD
                      </a>
                    </div>
                  )
                })}
              </div>
              <div
                className={`
          hidden w-full bg-white h-22 -mt-22 absolute
          md:block
        `}
                style={{ marginTop: '-5.5rem' }}
              ></div>
            </div>
          )
        })
      )}
    </>
  )
}
